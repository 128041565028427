import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const login = () => setIsLoggedIn(true);
    const logout = () => setIsLoggedIn(false);

    const checkAuthentication = async () => {
        try {
            const response = await axios.get('/api/tokenCheck', { withCredentials: true });
            const data = response.data;

            if (data.authentication) {
                setIsLoggedIn(true);
            }
        } catch (err) {
                console.log("Authentication check error", err);
            }
    };

    const refreshAccessToken = async () => {
        try {
            // Retrieve the refresh token from localStorage
            const refresh_token = localStorage.getItem('refreshToken');

            if (!refresh_token) {
                console.log('Refresh token not available');
                return;
            }

            const refreshResponse = await axios.post('/api/refresh', { refreshToken: refresh_token }, { withCredentials: true });
            const newAccessToken = refreshResponse.data.token;

            // Update the access token in localStorage
            localStorage.setItem('authToken', newAccessToken);

            setIsLoggedIn(true);
        } catch (error) {
            console.log('Error refreshing access token', error);
            logout();
        }
    };

    useEffect(() => {
        checkAuthentication();
    }, []); // Call checkAuthentication only on mount
    
    useEffect(() => {
        refreshAccessToken();
    }, [isLoggedIn]); // Call refreshAccessToken only when isLoggedIn changes
    


    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};