import './dynamicPost.scss'
import DynamicPostIngredients from './dynamic-post-ingredients'
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatPostDate } from '../../../utilityFunction/formatPostDate';
//import DatePostedFormat from '../'

export default function DynamicPost() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const postId = queryParams.get('postId');
    const option = queryParams.get('option');
    const [post, setPost] = useState(null);
    const [imageInfo, setImageInfo] =useState([]);
    const [containerSize, setContainerSize] = useState(1);



    const [listStyle, setListStyle] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [touchPosition, setTouchPosition] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
 
    // Location Profile Information
    const [locationProfile, setLocationProfile] = useState(window.innerWidth < 1157 ? 'locationHeight' : 'locationWidth')
    
    useEffect(()=> {
        const handleResize = () => {
            if (window.innerWidth < 1157) {
                setLocationProfile('locationHeight')
            } else {
                setLocationProfile('locationWidth')
            }
        };
      
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, [])


    useEffect(() => {
        //console.log(option);
        const fetchPost = async () => {
          try {
            const response = await axios.get(`/api/posts/${postId}`,{
                params: {
                  option: option
                }
            });
            setPost(response.data);
            setProfileImage(response.data.userPostingImage);
            
            loadImagesDisplay(response.data);
            //console.log(response.data);
            //console.log(`Sent dynamic page load for the post ${postId}`);
            //console.log(response.data.additionalImages[0].imageID_1.image)
          } catch (error) {
            console.error('Error fetching post:', error);
          }
        };
      
        console.log('Fetching post...');
        fetchPost();
        
        //getUserImage()
      }, [postId, option]);
         
    const loadImagesDisplay = (dataInfo) => {
        if (dataInfo && dataInfo.additionalImages) {
          const imagesData = dataInfo.additionalImages.map((imgData, index) => {
            const imageID = `imageID_${index+1}`;
            const { image, scale, positionX, positionY } = imgData[imageID]
            return { image, scale, positionX, positionY };
          })

          setImageInfo(imagesData);
        }
    };

    useEffect(() => {
        let timeoutID;

        const handleResize = () => {
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                const container = document.querySelector(".product-Image-carousel-container");
                const calculatedRelative = container.offsetWidth > 775 ? 1 : container.offsetWidth / 400;
                setContainerSize(calculatedRelative);
            }, 200)
        };
    
        // Initial calculation
        handleResize();
    
        window.addEventListener('resize', handleResize);
        return () => {
            clearTimeout(timeoutID);
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    if (!post) {
        return <p>Loading...</p>;
    }

    const handleListItemClick = (index) => {
        //setListStyle((selectedItem) => selectedItem === index ? null : index );
        if (listStyle !== index) {
            setListStyle(index);
        }
    }

    const handlePrevImage = () => {
        if (post.additionalImages && post.additionalImages.length > 0) {
            setCurrentImageIndex((prevIndex) => (prevIndex - 1 + post.additionalImages.length) % post.additionalImages.length);
        }
    };

    const handleNextImage = () => {
        if (post.additionalImages && post.additionalImages.length > 0) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % post.additionalImages.length);
        }
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;
    
        if (diff > post.additionalImages.length) {
            handleNextImage()
        }
    
        if (diff < -(post.additionalImages.length)) {
            handlePrevImage()
        }
    
        setTouchPosition(null)
    }

    const informationAboutPost = () => {
        return (
            <div className='dynamicPageProfileInfo'>
                { profileImage ? (
                    <div className='dynamicPageProfileImage'>
                        <img src={ profileImage }  alt="Profile"></img>
                    </div>
                ) : (
                    <div className='dynamicPageIconFiller'>
                        <i className="dynamicPageIconFillerInner fa-solid fa-user"></i>
                    </div>
                )
                }
                <div className='dynamicPageProfileDetails'>
                    <div>
                        <p>Maria Chirokova</p>
                    </div>
                    <div>
                        <i className="fa-regular fa-clock"></i>
                        <span>{ formatPostDate(post.datePosted)}</span>
                    </div>
                    <div>
                        <p>Date Modified:</p>
                        <span>{ formatPostDate(post.datePosted)}</span>
                    </div>
                    <div>
                        <i className="fa-regular fa-eye"></i>
                        <span>1111</span>
                    </div>
                </div>
            </div>
        )
    }
    

    return(
        <>
        <div>
            <div className="dynamicPageTitle">
                <header> 
                     {post.title}
                </header>
                <p>Recipe Id: {post.postNumber}</p>
            </div>
            <div>
                <div className='productInformationOutter'>
                    <div className='productInformationInner'>
                        {locationProfile === "locationHeight" && informationAboutPost()}
                        <div className='image-description-container'>
                            <div className='product-Image-carousel-container'>
                                <div className='productImageCarousel'
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}>
                                    {post.additionalImages && post.additionalImages.length > 0 ? (
                                        <img src={imageInfo[currentImageIndex].image} 
                                        alt={`Additional  ${currentImageIndex}`}
                                        style={{
                                            transform: `scale(${imageInfo[currentImageIndex]?.scale || 1})
                                            translate(${(imageInfo[currentImageIndex]?.positionX) * containerSize || 0}px, 
                                                ${(imageInfo[currentImageIndex]?.positionY) * containerSize || 0}px
                                            )
                                            `
                                        }} 
                                        />
                                    ) : (
                                        <img src={'images/face-cream.jpg'} alt="Default" />
                                    )}
                                </div>
                                    {post.additionalImages && post.additionalImages.length > 1 && (
                                        <>
                                        <div className='image-button-background image-carousel-left' onClick={handlePrevImage}>
                                            <i className='image-carousel-icon fa-solid fa-chevron-left'></i>
                                        </div>
                                        <div className='image-button-background image-carousel-right' onClick={handleNextImage}>
                                            <i className='image-carousel-icon fa-solid fa-chevron-right'></i>
                                        </div>
                                        </>
                                    )}
                                <div className='productImageCarousel-navigation'>
                                    { post.additionalImages && post.additionalImages.length > 0 && 
                                    (
                                        post.additionalImages.map((_, index) => {
                                            return <button key={index} onClick={() => setCurrentImageIndex(index)} className={ index === currentImageIndex ? "image-indicator-location" : "image-indicator-location about-indicator-inactive"}></button>
                                        })
                                    )}
                                </div>
                            </div>
                            <div className='productDescriptionSection'>
                                <div className='productDescriptionShort'>
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-weight-hanging"></i>
                                            <div>
                                            <h3>{post.generalInfo && post.generalInfo[0] && post.generalInfo[0].weight ? post.generalInfo[0].weight : "500"}g</h3>
                                                <p>Approximate Weight</p>
                                            </div>
                                        </li>
                                        <li>
                                            <i className="fa-regular fa-clock"></i>
                                            <div>
                                            <h3>{post.generalInfo && post.generalInfo[0] && post.generalInfo[0].time ? post.generalInfo[0].time : "15"} min</h3>
                                                <p>Aproximate Preperation Time</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='productDescriptionLong'>
                                <h3>Description</h3>
                                <p className='productDescriptionLongPar'>{post.description}</p>
                                </div>
                            </div>
                        </div>
                        {locationProfile === "locationWidth" && informationAboutPost()}
                    </div>
                    <div className='divider-dynamic-page'>
                    </div>    
                    <div className='dropDownMenuContainer'>
                        <ul className='dropDownMenuTechnologies'>
                            {['Ingredients', 'Technology'].map((item, index) => (
                                <li
                                    key={index}
                                    className={listStyle === index ? 'active' : ''}
                                    onClick={() => handleListItemClick(index)}
                                >
                                {item}
                            </li>
                            ))}
                        </ul>
                        {listStyle !== null  && (
                            <>
                            {listStyle === 0 && (
                                <>
                                    {post.ingredientsA[0].name !== "" && (
                                        <DynamicPostIngredients header="A" ingredients={post.ingredientsA}/>
                                    )}
                                    {post.ingredientsB[0].name !== "" && (
                                        <DynamicPostIngredients header="B" ingredients={post.ingredientsB}/>
                                    )}
                                    {post.ingredientsC[0].name !== "" && (
                                        <DynamicPostIngredients header="C" ingredients={post.ingredientsC}/>
                                    )}
                                </>
                                
                            )}
                            {listStyle === 1 && (
                                <div className='dropDownMenuDetailsTechnology-container'>
                                    <ul className='dropDownMenuDetailsTechnology'>
                                    {post.technologies.map((tech, index) => (
                                        <>
                                            <li key={index}>
                                                <div className='dropDownMenuDetailsTechnologyNumbers'>
                                                    <span>{index + 1}</span>
                                                </div>
                                                <div className='details-menu-details-ingredients'>
                                                    <p>{tech.description}</p>
                                                </div>
                                            </li>
                                            {index !== post.technologies.length - 1 &&
                                                <li className='divider-technology-menu'></li>
                                            }
                                        </>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {listStyle === 2 && (
                                <div>
                                    <ul className='dropDownMenuDetailsQA'>
                                        <li>Aswers</li>
                                        <li>Aswers</li>
                                        <li>Aswers</li>
                                        <li>Aswers</li>
                                        <li>Aswers</li>
                                        <li>Aswers</li>
                                        <li>Aswers</li>
                                    </ul>
                                </div>
                            )}
                            </>
                            )}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}