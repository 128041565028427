import "./blogtypetemplate.scss"
import React, { useEffect, useState } from "react";
import { formatPostDate } from '../../../utilityFunction/formatPostDate';
import BlogHeaderTypeOrder from "../../blog-order-components/BlogStyleType";
import Breadcrumbs from "../../navigation-components-blog/BreadcrumbNav";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

export default function BlogPostsTemplate( { option } ) {
  const [posts, setPosts] = useState([]);
  const [postsTitle, setPostsTitle] = useState('General Posts');
  const [postStyle, setPostStyle] = useState(window.innerWidth < 768 ? 'blog-list-style-2' : 'blog-list-style-1');

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setPostStyle('blog-list-style-2')
      } else {
        setPostStyle('blog-list-style-1')
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  //console.log(option);
  useEffect(() => {
    // Fetch posts from the backend API
    const fetchPosts = async () => {
      try {
        const response = await axios.get('/api/posts', { params: { option } });
        const sortedPosts = response.data.sort((a, b) => b.id - a.id);
        setPosts(sortedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  //Setting title effect
  useEffect(() => {
    if (option === 'Scrubs') {
      setPostsTitle('Scrubs');
    } else if (option === 'BodyWash') {
      setPostsTitle('For Body');
    } else if (option === 'FaceCream'){
      setPostsTitle('For Face');
    } else {
      setPostsTitle('Articles');
    }

  }, [option]); // Re-run effect whenever option changes


  const handleDelete = async (postId) => {
    try {
        await axios.delete(`/api/posts/${postId}`, { params: { option } });
        // Update the local State to reflect the deletion
        setPosts(posts.filter((post) => post.id !== postId));
    } catch(error) {
        console.log('Error deleting post:', error);
    }
  };

 
  const transformedText = (description) => {
    let dotCount = 0
    let textReturn = ""

    for (let i = 0; i < description.length; i++) {
      if (description[i] === '.'){
        dotCount++;
        if (dotCount === 4) {
          textReturn = description.slice(0, i+1);
          return textReturn;
        }
      }
    }
    return description
  }

  const togglePostStyle = () => {
    setPostStyle(prevStyle => prevStyle === 'blog-list-style-1' ? 'blog-list-style-2' : 'blog-list-style-1');
  }
  
  return (
    <div className="blog-pages-posts-container">
      <Breadcrumbs />
      <BlogHeaderTypeOrder toggleStyle={togglePostStyle} postStyle={postStyle}/>
      <div className="blog-singlePost">
        {posts.map((post) => (
          <div key={post.id} className={postStyle}>
            {postStyle === 'blog-list-style-1' && (
                <Link to={`/DynamicPosts?postId=${post.id}&option=${option}`} className="dynamicPostRedirectStyle">
                  <div className="blog-pages-posts-header-image-container">
                    <img
                      className="blog-singlePostImg"
                      src={post.imageHeader != null  && post.imageHeader != "null"? post.imageHeader : "https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"}
                      alt="Header Image"
                    />
                  </div>
                </Link>
                )}
            <div className="blog-list-style-information-container">
              <div className="blog-pages-posts-title-header">
                <p>{postsTitle}</p>
                {isLoggedIn &&
                <div className="blog-singlePostEdit">
                  <Link to={`/EditPost?postId=${post.id}&option=${option}`} className="editPost-Redirect">
                    <i className="far fa-edit"></i>
                  </Link>
                  <i className="blog-singlePostIcon far fa-trash-alt" onClick={() => handleDelete(post.id)}></i>
                </div>
                }
              </div>
              {postStyle === 'blog-list-style-2' && (
                <Link to={`/DynamicPosts?postId=${post.id}&option=${option}`} className="dynamicPostRedirectStyle">
                  <div className="blog-pages-posts-header-image-container">
                    <img
                      className="blog-singlePostImg"
                      src={post.imageHeader != null  && post.imageHeader != "null"? post.imageHeader : "https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"}
                      alt="Header Image"
                    />
                  </div>
                </Link>
                )}
              <div className="blog-singlePostTitle">
                <Link to={`/DynamicPosts?postId=${post.id}&option=${option}`} className="dynamicPostRedirectStyle-text">
                  {post.title}
                </Link>
              </div>
              <p className="blog-singlePostDesc">
                {transformedText(post.description)}
              </p>
              <br/>
              <div className="blog-list-style-time-container">
                <div className="title-tag-hash">#</div>
                <i className="fa-regular fa-clock"></i>
                <span className="blog-list-style-time-posted">{formatPostDate(post.datePosted)}</span>
              </div>
              <div className="blog-pages-posts-divider">
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}