import './listmenu.scss';
import { useState } from 'react';
import React from 'react';

export default function ListMenu({ data, setData, fieldNames }) {
  const addAnotherField = () => {
    const newData = {};
    for (const key in fieldNames) {
      newData[key] = '';
    }
    setData([...data, newData]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    //console.log(value);
    setData(updatedData);
  };

  const removeField = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  return (
    <div className='write-page-input-ingredients-container'>
      {data.map((item, index) => (
        <div key={index}>
          {Object.entries(fieldNames).map(([fieldName, placeholder]) => (
            <input
              className='input-fields-list-write-page'
              key={fieldName}
              placeholder={placeholder}
              type="text"
              value={item[fieldName]}
              onChange={(e) => handleInputChange(index, fieldName, e.target.value)}
            />
          ))}
          <button className='write-page-input-ingredients-remove' onClick={() => removeField(index)}>Remove</button>
        </div>
      ))}
      <button className='write-page-input-ingredients-add' onClick={addAnotherField}>Add Another</button>
    </div>
  );
}
