import './listmenutechnologies.scss';

export default function ListMenuTechnologies({ data, setData, fieldNames }) {
    const addAnotherField = () => {
      const newData = {};
      for (const key in fieldNames) {
        newData[key] = '';
      }
      setData([...data, newData]);
    };
  
    const handleInputChange = (index, field, value) => {
      const updatedData = [...data];
      updatedData[index][field] = value;
      //console.log(value);
      setData(updatedData);
    };
  
    const removeField = (index) => {
      const updatedData = [...data];
      updatedData.splice(index, 1);
      setData(updatedData);
    };
  
    return (
        <>
        {data.map((item, index) => (
          <div className='technologies-input-outer' key={index}>
            {Object.entries(fieldNames).map(([fieldName, placeholder]) => (
              <div key={fieldName} className='technologies-input-number'>
                {fieldName === 'number' ? (
                  <input
                    placeholder={placeholder}
                    type="text"
                    value={item[fieldName]}
                    onChange={(e) => handleInputChange(index, fieldName, e.target.value)}
                  />
                ) : (
                  <textarea
                    className="text-area-technologies-option"
                    placeholder={placeholder}
                    value={item[fieldName]}
                    onChange={(e) => handleInputChange(index, fieldName, e.target.value)}
                  />
                )}
              </div>
            ))}
            <button className = "technologies-button-remove" onClick={() => removeField(index)}>Remove</button>
            <button className = "technologies-button-add" onClick={addAnotherField}>Add Another</button>
          </div>
        ))}
        
      </>
    );
}