import "./blogsingle.scss"
import React from 'react';
import Articles from "../../components/blog-posts-functionality/blog-page-post-lists/ArticlesPosts";
import BlogFooter from "../../components/blog-footer/BlogFooter";
import PageHeaders from "../../components/blog-header/PageHeaders";


export default function ArticlesComponentMain() {
    return (
        <>
            <div className="blog-single-container">
                <Articles/>
            </div>
            <BlogFooter/>
        </>
    )
}