import './blogstyletype.scss';

export default function BlogHeaderTypeOrder({ toggleStyle, postStyle }) {

    return(
        <>
        <div className='blog-toggle-style'>
            {postStyle === 'blog-post' ? (
                <i className="blog-change-box-size fa-solid fa-grip-lines" onClick={toggleStyle}></i>
            ) :
                <i className="blog-change-box-size fa-solid fa-grip" onClick={toggleStyle}></i>
            }
        </div>
        </>
    )
}