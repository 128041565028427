import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import DropMenuLogin from '../drop-down-menus/DropMenuLogin';
import logOut from '../../utilityFunction/logout';
import React from 'react';


function TopBar() {
    const { isLoggedIn } = useAuth();

    const location = useLocation();

    //const [currentTopBar, setCurrentTopBar] = useState('top-bar-blog');
    //const [logoName, setLogoName] = useState('top-bar-left-logo');

    const [topBarColours, setTopBarColors] = useState([{
        logoColor: 'top-bar-left-logo',
        backgroundColor: 'top-bar-blog',
        searchIconColor: 'top-bar-right-search-img',
        logoLink: "images/studio_m23_ivory.png",
        dropMenuOuter: 'SignInContainerMain',
        dropMenuText: 'top-bar-right-login',
        dropMenuIcon: 'top-bar-right-icon',
     }])
    
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setTopBarColors([
                    {
                        backgroundColor: 'top-bar-blogScrolled',
                        logoColor: 'top-bar-left-logo',
                        searchIconColor: 'top-bar-right-search-img',
                        logoLink: "images/studio_m23_ivory.png",
                        dropMenuOuter: 'SignInContainerScrolled',
                        dropMenuText: 'top-bar-right-login-scrl',
                        dropMenuIcon: 'top-bar-right-icon-scrl'
                    }
                ])
            } 
            else {
                if (location.pathname === '/') {
                    setTopBarColors([
                        {
                            logoColor: 'top-bar-left-logo-home-location',
                            backgroundColor: 'top-bar-blog-home-location',
                            searchIconColor: 'top-bar-right-search-img-home-location',
                            logoLink: "images/studio_m23.png",
                            dropMenuOuter: 'SignInContainerMain',
                            dropMenuText: 'top-bar-right-login',
                            dropMenuIcon: 'top-bar-right-icon'
                        }
                    ])
                } else {
                    setTopBarColors([
                        {
                            logoColor: 'top-bar-left-logo',
                            backgroundColor: 'top-bar-blog',
                            searchIconColor: 'top-bar-right-search-img',
                            logoLink: "images/studio_m23_ivory.png",
                            dropMenuOuter: 'SignInContainerScrolled',
                            dropMenuText: 'top-bar-right-login-scrl',
                            dropMenuIcon: 'top-bar-right-icon-scrl'
                        }
                    ])
                }
            }
        })
    }, [location.pathname]);

    
    useEffect(() => {
        //setLogoName(location.pathname === '/' ? 'top-bar-left-logo-home-location'  : 'top-bar-left-logo');
        if (location.pathname === '/') {
            setTopBarColors([
                {
                    logoColor: 'top-bar-left-logo-home-location',
                    backgroundColor: 'top-bar-blog-home-location',
                    searchIconColor: 'top-bar-right-search-img-home-location',
                    logoLink: "images/studio_m23.png",
                    dropMenuOuter: 'SignInContainerMain',
                    dropMenuText: 'top-bar-right-login',
                    dropMenuIcon: 'top-bar-right-icon'
                }
            ])
        } else {
            setTopBarColors([
                {
                    logoColor: 'top-bar-left-logo',
                    backgroundColor: 'top-bar-blog',
                    searchIconColor: 'top-bar-right-search-img',
                    logoLink: "images/studio_m23_ivory.png",
                    dropMenuOuter: 'SignInContainerScrolled',
                    dropMenuText: 'top-bar-right-login-scrl',
                    dropMenuIcon: 'top-bar-right-icon-scrl'
                }
            ])
        }
    }, [location.pathname]);
    
    const handleLogout = () => {
        logOut();
        navigate('/');
    }

    return (
        <>
            <div className={topBarColours[0].backgroundColor}>
                <div className="top-bar-blog-inner">
                    <div className="top-bar-left">

                    </div>
                    {/*<div className="top-bar-left">
                        <ul className="top-bar-list-navigation">
                            <li className="top-bar-list-navigation-items">
                                <Link className="link" to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="top-bar-list-navigation-items">
                                <Link className="link" to="/AllPosts">
                                    Blog
                                </Link>
                            </li>
                            <li className="top-bar-list-navigation-items">About</li>
                            { isLoggedIn && 
                            <>
                                <li className="top-bar-list-navigation-items">
                                    <Link className="link" to="/Settings">Settings</Link>
                                </li>
                                <li className="top-bar-list-navigation-items">
                                    <Link className="link" to="/Write">Write</Link>
                                </li>
                                <li className="top-bar-list-navigation-items" onClick={handleLogout} >Logout</li>
                            </>
                            }
                        </ul>
                        </div>*/}
                    <div className="top-bar-center">
                        <Link className="top-bar-left-logo-container" to="/">
                            <img className="top-bar-log-image-container" src={topBarColours[0].logoLink}></img>
                            {//<p className={ topBarColours[0].logoColor }>Studio M23</p>
                            }
                        </Link>
                    </div>
                    <div className="top-bar-right">
                        <div className="top-bar-right-container">
                            <i className={`${topBarColours[0].searchIconColor} fa-solid fa-magnifying-glass`}></i>
                            <DropMenuLogin 
                            dropMenuOuter={topBarColours[0].dropMenuOuter}
                            dropMenuText={topBarColours[0].dropMenuText}
                            dropMenuIcon={topBarColours[0].dropMenuIcon}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar;