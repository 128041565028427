import BlogFooter from "../../components/blog-footer/BlogFooter";
import './signup.scss'
import { useState, useEffect } from "react";
import axios from "axios";
import React from 'react';


export default function SignUp() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const [profileImage, setProfileImage] = useState(null);
    const [profileImageFile, setProfileImageFile] = useState(null);

    const emailEvent = (event) => {
        setEmail(event.target.value);
    }

    const userNameEvent = (event) => {
        setUserName(event.target.value);
    }

    const passwordEvent = (event) => {
        setPassword(event.target.value);
    }

    const handleImageChangeHead = (event) => {
        const selectFile = event.target.files[0];
        setProfileImageFile(selectFile);

        const reader = new FileReader();
        reader.onload = () => {
            setProfileImage(reader.result);
        };
        
        if (selectFile) {
            reader.readAsDataURL(selectFile);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('userName', userName);
            formData.append('password', password);
            formData.append('profileImage', profileImageFile);


            const response = await axios.post('/api/signup', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            console.log('Server Response', response.data);
        } catch (error) {
            console.log("Error Posting Data:", error);
        }
        setUserName('');
        setEmail('');
        setPassword('');
        setProfileImage(null);
        setProfileImageFile(null);
    }
    return(
        <>
        <div className="singUpBlog">
            <h1>Sign Up</h1>
            <form className= "signUpForm" onSubmit={handleSubmit}>
                <div className="sing-up-image">
                    <img
                    src={profileImage ? profileImage : "https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"}
                    alt=""
                  />
                  <label htmlFor="fileInput">
                    <i className="signUPIconPP far fa-user-circle"></i>{" "}
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    className="settingsPPInput"
                    onChange={handleImageChangeHead}
                  />
                </div>
                <label>Email:</label>
                <input type="text" name="email" value={email} onChange={emailEvent}/>
                <label>Username:</label>
                <input type="text" name="username" value={userName} onChange={userNameEvent}/>
                <label>Password:</label>
                <input type="password" name="password" value={password} onChange={passwordEvent}/>
                <button type="submit">Submit</button>
            </form>
        </div>
        <BlogFooter/>
        </>
    )
};