// logout.js
import axios from "axios";

const logout = async () => {
    try {
        const response = await axios.post('/api/logout');
        const data = response.data;

        if (data) {
            console.log(`${data.message}`);
            localStorage.removeItem('refreshToken');

            window.location.reload(true);    
        }

    } catch (error) {
        console.log("Error Logging Out", error);
    }
};

export default logout;