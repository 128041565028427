import "./blogsingle.scss"
import React from 'react';
import BlogFooter from "../../components/blog-footer/BlogFooter";
import BlogScrub from '../../components/blog-posts-functionality/blog-page-post-lists/ScrubsPosts';
import PageHeaders from "../../components/blog-header/PageHeaders";

export default function ScrubComponentMain() {
    return (
        <>
            {//<PageHeaders title = "Scrubs" />
            }
            <div className="blog-single-container">
                <BlogScrub/>
            </div>
            <BlogFooter/>
        </>
    )
}