import BlogHeader from "../../components/blog-header/BlogHeader";
import BlogPosts from "../../components/RetiredComponents/blog-home-posts/BlogPosts";
import BlogSideBar from "../../components/blog-sidebar/BlogSideBar";
import Breadcrumbs from "../../components/navigation-components-blog/BreadcrumbNav"
import BlogFooter from "../../components/blog-footer/BlogFooter"
import HomePageButton from "../../components/blog-home-page-options/blogHomePageOptions"
import React from 'react';
import './bloghome.scss'

function BlogHome() {
    return(
        <>
            <div className="blog-home-main">
                <BlogHeader TitleSmall = "blog" TitleLarge = "Blog Maria"/>
                <div className="blog-home-outer">
                        <HomePageButton/>
                </div>
                <BlogFooter/>
            </div>
        </>
    )
}

export default BlogHome;