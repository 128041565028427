import "./blogtypetemplate.scss"
import React, { useEffect, useState } from "react";
import BlogPostsTemplate from "./BlogTypeTemplate";


export default function ListAll() {
  return (
    <>
        <BlogPostsTemplate option={"Articles"}/>
    </>
  );
}