import './headerimagewrite.scss';
import { useState } from 'react';
import React from 'react';

export default function HeaderImageWrite( {handleImageChangeHead, handleDeleteHeader, headerData, toggleImageInput} ) {
    
    return(
        <div className="fileInputBackground-header">
            <div className="fileInput-Remove-Symbol">
                <label onClick={handleDeleteHeader}>
                    <i className="blog-addRemoveImagesIcon fa-solid fa-trash"></i>
                </label>  
            </div>
            <div className="fileInput-close-symbol">
                <label onClick={toggleImageInput}>
                    <i className="blog-closeImageIcon fas fa-times-circle"></i>
                </label>
            </div>
            {!headerData && (
            <label htmlFor="blog-fileInput-Header" className="fileInputBackgroundHeader-image-closed">
                <div className="fileInput-files-symbol">
                    <label htmlFor="blog-fileInput-Header">
                        <i className="blog-OpenFileIcon fa-solid fa-photo-film"/>
                    </label>
                </div>
                <div className="fileInputBackground-top-text">
                    <span className="fileInputBackground-top-text">Add Header Image</span>
                </div>
                <div className="fileInputBackground-bot-text">
                    <span>or drag and drop</span>
                </div>
                <input id="blog-fileInput-Header" type="file" style={{ display: "none" }} onChange={handleImageChangeHead}/>
            </label>
            )}
            {headerData && (
            <div className="fileInputBackgroundHeader-image-open">
                <div className="blog-writeDisplay-Image">
                    <img src={headerData} alt="Uploaded"/> 
                </div>
            </div>   
            )}
        </div>
    )
}