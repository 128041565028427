import React from 'react';
import BlogCarouselHeader from './BlogHeaderCarousel';
import './blogheader.scss';

function BlogHeader(props) {

    const BlogHeaderWhiteDot = () => (
        <div className='blog-header-main-whitedot-outside'>
                <div className='blog-header-main-whitedot-inside'>
                </div>
        </div>
    )

    return(
        <>
        <BlogCarouselHeader />
        <div className='Blog-Header-Main-Container'>
            <div className='Blog-Header-Main-Container-Image'>
                <img className='Blog-header-background-IMG'></img>
            </div>
            <div className='Blog-Header-Main-Top-BackGround'>
                <div className='Blog-Header-Main-Top-Container'>
                    <p className='floating-text-pos-1 blog-header-floating-text-style'>Essential Oil</p>
                    <p className='floating-text-pos-2 blog-header-floating-text-style'>Aromatherapy</p>
                    <div className='Blog-Header-Main-Top-Text'>
                        <div className='Blog-Header-Main-Inner-Top-Text'>
                            <span>Natural</span>
                            <div className='white-dot-position-1'>
                                <BlogHeaderWhiteDot />
                            </div>
                        </div>
                        <div className='Blog-Header-Main-Inner-Bot-Text'>
                            <span>COSMETICS</span>
                            <div className='white-dot-position-2'>
                                <BlogHeaderWhiteDot />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Blog-Header-Main-Bottom-Container'>
                    <p className='floating-text-pos-3 blog-header-floating-text-style'>Skin Care</p>
                    <p className='floating-text-pos-4 blog-header-floating-text-style'>Texture</p>
                    <div className='Blog-Header-Main-Bottom-Text'>
                        <div className='Blog-Header-Main-Inner-Bottom-Top-Text'>
                            <span>Beauty secrects from your kitchen : homemade cosmetics and</span>
                            <div className='white-dot-position-3'>
                                <BlogHeaderWhiteDot />
                            </div>
                        </div>
                        <div className='Blog-Header-Main-Inner-Bottom-Bot-Text'>
                            <div className='Blog-Header-Main-Inner-Bottom-Bot-Text-Image'>
                                <img src='/images/mainpagePaint.png'></img>
                            </div>
                            <span>Natural Recipes</span>
                            <div className='white-dot-position-4'>
                                <BlogHeaderWhiteDot />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Blog-Header-Main-Right-Images'>
                <div className='Blog-Header-Main-Right-Images-Container'>
                    <img src='/images/mainpageimage.png' ></img>
                </div>
                <div className='Blog-Header-Main-Right-Images-New-Container'>
                    <div>
                        <span>new</span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}



export default BlogHeader;