import "./editingredients.scss"

export default function EditIngredients({ typeOfField, data, setData, fieldNames }) {
    
    const AddNewField = () => {
        const newField = {};
        for (const key in fieldNames) {
            newField[key] = '';
        }
        setData([...data, newField])
    }

    const DeleteField = (index) => {
        const newField = [...data];
        newField.splice(index, 1);
        setData(newField);
    } 

    const handleInputChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index][field] = value;
        setData(updatedData);
    };

    return(
        <>
        {
        <div className="Ingredients-Inner-Fields-Edit">
            {typeOfField === "ingredients" ?  data.map((item, index) => (
                <div key={index} className="Ingredients-Edit-Inner">
                    {Object.entries(fieldNames).map(([fieldName, placeholder]) =>(
                        <input
                        key={fieldName} 
                        className='input-fields-list-edit-page'
                        placeholder={placeholder}
                        type="text"
                        value={item[fieldName]}
                        onChange={e => handleInputChange(index, fieldName, e.target.value)}
                        />
                    ))}
                    <button onClick={() => DeleteField(index)}>Delete</button>
                </div>
            ))
            : data.map((item, index) => (
                <div key={index} className='Technologies-Edit-Inner'>
                    {Object.entries(fieldNames).map(([fieldName, placeholder]) => (
                        <div key={fieldName} className="Technologies-Edit-Fields">
                            {fieldName === 'number' ? (
                            <input
                                key={fieldName} 
                                placeholder={placeholder}
                                type="text"
                                value={item[fieldName]}
                                onChange={(e) => handleInputChange(index, fieldName, e.target.value)}
                            />
                            ) : (
                            <textarea
                                className="text-area-technologies-option"
                                placeholder={placeholder}
                                value={item[fieldName]}
                                onChange={(e) => handleInputChange(index, fieldName, e.target.value)}
                            />
                            )}
                        </div>
                    ))
                    }

                </div>
            ))
        }
             <button className="Edit-Add-Button" onClick={AddNewField}>Add</button>
        </div>
        }
        </>
    )
}