export function formatPostDate(postDate, title) {
  const currentDateTime = new Date();
  const postDateTime = new Date(postDate);
  const timeDifference = currentDateTime - postDateTime;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));


  if (hoursDifference < 24) {
    if (hoursDifference < 1) {
      // If less than 1 hour, use minutes
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    }
  } else {
    // Format the date in the format "Month Day, Year"
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return postDateTime.toLocaleDateString(undefined, options);
  }
}


export const getCurrentDateTime = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};