import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './bloghomepageoptions.scss'


export default function HomePageButton() {
    // Button That redirects to the page with the singleBlog slide
    return(
        <div className='Main-page-button-container'>
            <div className='redirect-blog-home'>
                <Link className='redirect-blog-home-link' to="/FaceCream">
                    <div className='redirect-title-postion'>
                        <p className='redirect-title-home redirect-title-home-body-color'>For Face</p>
                    </div>
                    <div className='redirect-blog-home-images'>
                        <img src='/images/for-face-II.png'></img>
                    </div> 
                </Link>
            </div>
            <div className='redirect-blog-home'>
                <Link className='redirect-blog-home-link' to="/BodyWash">
                    <div className='redirect-title-postion'>
                        <p className='redirect-title-home redirect-title-home-face-color'>For Body</p>
                    </div>
                    <div className='redirect-blog-home-images'>
                        <img src='/images/for-body-II.png'></img>
                    </div> 
                </Link>
            </div>
            <div className='redirect-blog-home'>
                <Link className='redirect-blog-home-link' to="/Scrubs">
                    <div className='redirect-title-postion'>
                        <p className='redirect-title-home redirect-title-home-scrubs-color'>Scrubs</p>
                    </div>
                    <div className='redirect-blog-home-images'>
                        <img src='/images/for-scrub-II.png'></img>
                    </div> 
                </Link>
            </div>
            <div className='redirect-blog-home'>
                <Link className='redirect-blog-home-link' to="/Articles">
                    <div className='redirect-title-postion'>
                        <p className='redirect-title-home redirect-title-home-general-color'>Articles</p>
                    </div>
                    <div className='redirect-blog-home-images'>
                        <img src='/images/comingsoontext.png'></img>
                    </div> 
                </Link>
            </div>
        </div>
    )
}
