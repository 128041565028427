import "./blogsingle.scss";
import React from 'react';
import BlogFooter from "../../components/blog-footer/BlogFooter";
import BodyWashPosts from '../../components/blog-posts-functionality/blog-page-post-lists/BodyWashPosts';
import PageHeaders from "../../components/blog-header/PageHeaders";

export default function BodyWashComponentMain() {
    return (
        <>
            <div className="blog-single-container">
                <BodyWashPosts/>
            </div>
            <BlogFooter/>
        </>
    )
}