import "./blogtypetemplate.scss"
import React, { useEffect, useState } from "react";
import BlogPostsTemplate from "./BlogTypeTemplate"


export default function BlogScrub() {
  return (
    <>
        <BlogPostsTemplate option={"Scrubs"}/>
    </>
  );
}