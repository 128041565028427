import { Link } from 'react-router-dom'
import { useAuth } from "../../context/AuthContext";
import './blogfooter.scss'

function BlogFooter(){
  const { isLoggedIn } = useAuth();

  return(
    <footer className='blogFooter'>
      <div className="col">
        <p>Explore</p>
        <ul>
          <Link to="/"><li>Home</li></Link>
          <Link to="/SinglePost"><li>Blog</li></Link>
          {isLoggedIn && (
          <>
            <Link to="/Write"><li>Write</li></Link>
            <Link to="/Settings"><li>Settings</li></Link>
          </>
          )}
        </ul>
      </div>
      <div className='copyright-center-position'>
        <p>Copyright © 2024 </p>
        <p>Studio M23</p>
      </div>
      <div className="col">
        <ul className='footer-social-links'>
          <li><img className="footer-right-link-icons" src="icons-buttons/facebookV2.svg"></img></li>
          <li><img className="footer-right-link-icons" src="icons-buttons/x-twitter.svg"></img></li>
          <li><img className="footer-right-link-icons" src="icons-buttons/pinterest.svg"></img></li>
          <li>
            <a href='https://www.instagram.com/studio_m23?igsh=eGl0ZTMyMmZ3OTRx' target='_blank' rel="noopener noreferrer">
              <img className="footer-right-link-icons" src="icons-buttons/instagram.svg"></img>
            </a>
          </li>
          <br/>
        </ul>
      </div>
    </footer>
  );
}

export default BlogFooter;