import { useState } from "react"
import './blogheadercarousel.scss'


const BlogCarouselHeader = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [touchPosition, setTouchPosition] = useState(null);
    const [nextSlideAnimationClass, setNextSlideAnimationClass] = useState(null);
    const [pauseSlide, setPauseSlide] = useState(false);

    const [prevSlideIndex, setPrevSlideIndex] = useState(0);

    const slideInfo = [
        {id: 1, 
            slideIMG: "images/HeaderCarouselPNG/HeaderC0.jpg", 
            styleBackPresent: "Blog-header-Mobile-Text-Inner-Back",
            styleHeaderC1: "Blog-Header-Text-1-1",
            styleHeaderC2: "Blog-Header-Text-1-2",  
            slideText1: "Everything you need to know about beauty and", 
            slideText2: "Skin Care"
        },
        {id: 2, 
            slideIMG: "images/HeaderCarouselPNG/HeaderC1.jpg",
            styleBackPresent: "Blog-header-Mobile-Text-Inner-Back", 
            styleHeaderC1: "Blog-Header-Text-2-1",
            styleHeaderC2: "Blog-Header-Text-2-2",
            styleHeaderC3: "Blog-Header-Text-2-3", 
            slideText1: "Beauty Secrets from Your Kitchen:", 
            slideText2: "Homemade Cosmetics and",
            slideText3: "NATURAL RECIPES"
        },
        {id: 3, 
            slideIMG: "images/HeaderCarouselPNG/HeaderC2.jpg",
            styleBackPresent: "Blog-header-Mobile-Text-Inner-Back", 
            styleHeaderC1: "Blog-Header-Text-3-1",
            styleHeaderC2: "Blog-Header-Text-3-2", 
            slideText1: "Each product is lovingly made with", 
            slideText2: "the purest ingredients"
        },
        {id: 4, 
            slideIMG: "images/HeaderCarouselPNG/HeaderC3.jpg",
            styleBackPresent: "Blog-header-Mobile-Text-Inner-Back", 
            styleHeaderC1: "Blog-Header-Text-4-1",
            slideText1: "Aromatherapy"
        }
    ]

    const goNextSlide = () => {
        const nextIndex = (slideIndex + 1) % slideInfo.length;
        setPrevSlideIndex(slideIndex);
        setSlideIndex(nextIndex);
    }

    const goPrevSlide = () => {
        const prevIndex = (slideIndex - 1 + slideInfo.length) % slideInfo.length;
        setPrevSlideIndex(slideIndex);
        setSlideIndex(prevIndex);
    }

    const handleTouch = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    }

    const handleTouchMove = (e) => {
        
        if (touchPosition == null){
            return
        }

        const currentTouchPosition = e.touches[0].clientX;
        const diff = currentTouchPosition - touchPosition;
        //const numDiff = diff + touchPosition;

        const threshold = 0;

        if (Math.abs(diff) > threshold) {
            if (diff > 0) {
                goPrevSlide();
            } else {
                goNextSlide();
            }
        }

        setTouchPosition(null);
    }

    


    return(
        <>
        <div className="BlogHeaderCarousel"
        onTouchStart={handleTouch}
        onTouchMove={handleTouchMove}
        onTouchEnd={() => setNextSlideAnimationClass(null)}>
            {slideInfo.map((slide, index) => (
                <div key={slide.id} 
                className={index === slideIndex ? "header-carousel-slide" : "header-carousel-slide slide-hidden"}
                style={{ transform: `translateX(${(index - slideIndex) * 100}%)` }}
                >
                    <div className='Blog-Header-Image-Container'>
                        <img className='Blog-header-background-Mobile' src={slide.slideIMG}></img>
                    </div>
                    <div className='Blog-header-Mobile-Text-Container'>
                        <div className={slide.styleBackPresent}>
                            <div className='Blog-header-Mobile-Text-Inner'>
                                {slide.slideText1 && <span className={`Blog-header-Mobile-Text-1 ${slide.styleHeaderC1}`}>{slide.slideText1}</span>}
                                {slide.slideText2 && <span className={`Blog-header-Mobile-Text-2 ${slide.styleHeaderC2}`}>{slide.slideText2}</span>}
                                {slide.slideText3 && <span className={`Blog-header-Mobile-Text-2 ${slide.styleHeaderC3}`}>{slide.slideText3}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div className="Blog-header-navigation-image">
                {slideInfo.map((_, index) => (
                    <button 
                    key={index} 
                    onClick={() => setSlideIndex(index)}  
                    className= { index === slideIndex ? 
                        "header-indicator-image" : 
                        "header-indicator-image header-indicator-inactive"}>
                    </button>
                )
            )}
            </div>
        </div>
        </>
    )
}


export default BlogCarouselHeader;