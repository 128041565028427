import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import "./breadcrumbnav.scss";

function Breadcrumbs( { specificOption, titlePost } ) {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const [optionPathName, setOptionPathName] = useState('');
    const [pathNameMap, setPathNameMap] = useState('');

    //console.log(specificOption);
    const createDynamicPostLink = () => {
      console.log(pathnames[0])
      switch (specificOption) {
        case 'Articles':
          setOptionPathName('Articles');
          break;
        case 'FaceCream':
          setOptionPathName('For Face');
          break;
        case 'BodyWash':
          setOptionPathName('For Body');
          break;
        case 'Scrubs':
          setOptionPathName('Scrubs');
          break;
        default:
          setOptionPathName('');
      }  
    }; 

    const changeStaticNameLink = () => {
      console.log(pathnames[0])
      switch (pathnames[0]) {
        case 'Articles':
          setPathNameMap('Articles');
          break;
        case 'FaceCream':
          setPathNameMap('For Face');
          break;
        case 'BodyWash':
          setPathNameMap('For Body');
          break;
        case 'Scrubs':
          setPathNameMap('Scrubs');
          break;
        case 'DynamicPosts':
          setPathNameMap(titlePost);
          break;
        default:
          setPathNameMap('');
      }
    }

    useEffect(() => {
      if (specificOption) {
        createDynamicPostLink();
      }
      changeStaticNameLink();
    }, [specificOption]);
    
    return (
      <div className="breadcrumbNavContainer">
        <Link className="breadcrumbNav" to="/">Home</Link>
        { specificOption && (
          <>
          <span className="breadcrumb-arrow"> - </span>
          <Link className="breadcrumbNav" to={`/${specificOption}`}>{optionPathName}</Link>
          </>
        )}
        {pathnames.map((name, index) => (
          <span key={name}>
            <span className="breadcrumb-arrow"> - </span>
            <span className="breadcrumbNav">{pathNameMap}</span>
          </span>
        ))}
    </div>
    );
  }
  
  export default Breadcrumbs;