import BlogFooter from "../../components/blog-footer/BlogFooter"
import EditIngredients from "./editIngredients";
import axios from "axios";
import "./editpost.scss"
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function EditPost() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const postId = queryParams.get('postId');
    const option = queryParams.get('option');

    const [post, setPost] = useState(null);

    const [infoArray, setInfoArray] = useState([{titleName: "Information", description: "Random Text"}]);
    // Handles the Bottom Inputs
    const [ingredientsA, setIngredientsA] = useState([{ name: '', percentage: '', amount: '' }]);
    const [ingredientsB, setIngredientsB] = useState([{ name: '', percentage: '', amount: '' }]);
    const [ingredientsC, setIngredientsC] = useState([{ name: '', percentage: '', amount: '' }]);
    const [technologies, setTechnologies] = useState([{ number: '', description: '' }]);

    const [imagefileOpen, setImageFileOpen] = useState(null);

    //Header Data
    const [headerData, setHeaderData] = useState(null);
    const [displayHeader, setDisplayHeader] = useState(null);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageFile, setImageFile] = useState([]);
     // Handles Image Resizing
    const [imageStates, setImageStates] = useState([]);
    const [imageDragIndex, setimageDragIndex] = useState(null);


    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(
                    `/api/posts/${postId}`,
                    {
                        params: {
                            option: option
                        }
                    }
                );
                setPost(response.data);

                if (response.data.title){
                    setInfoArray([{ titleName: response.data.title, description: response.data.description }])  
                }
                setIngredientsA(response.data.ingredientsA);
                setIngredientsB(response.data.ingredientsB);
                setIngredientsC(response.data.ingredientsC);
                setTechnologies(response.data.technologies);
                

                if (response.data.imageHeader) {
                    setHeaderData(response.data.imageHeader);
                    setDisplayHeader(response.data.imageHeader);
                    setImageFileOpen(true);
                }
                loadImagesDisplay(response.data);


            } catch (error) {
                console.error('Error fetching post:', error);
            }
            
        }; 

        console.log("Fetching Edit Post...")
        fetchPost();
    }, [])

    const handleSliderChange = (e) => {
        const newImageStates = [...imageStates];
        newImageStates[currentImageIndex].scale = parseFloat(e.target.value);
        setImageStates(newImageStates);
      };
    
    
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (imageDragIndex == null) return;

            const deltaX = e.clientX - imageStates[imageDragIndex].prevPosition.x;
            const deltaY = e.clientY - imageStates[imageDragIndex].prevPosition.y;

            const newImageStates = [...imageStates];
            newImageStates[imageDragIndex].position = {
            x: newImageStates[imageDragIndex].position.x + deltaX,
            y: newImageStates[imageDragIndex].position.y + deltaY,
            };
            newImageStates[imageDragIndex].prevPosition = { x: e.clientX, y: e.clientY };

            setImageStates(newImageStates);
        };

        const handeTouchMove = (e) => {
            if (imageDragIndex == null || e.touch.length !== 1) return;

            const touch = e.touches[0];
            const deltaX = touch.clientX - imageStates[imageDragIndex].prevPosition.x;
            const deltaY = touch.clientY - imageStates[imageDragIndex].prevPosition.y;
            
            const newImageStates = [...imageStates];
            newImageStates[imageDragIndex].position = {
                x: newImageStates[imageDragIndex].position.x + deltaX,
                y: newImageStates[imageDragIndex].position.y + deltaY,
            };
            newImageStates[imageDragIndex].prevPosition = { x: touch.clientX, y: touch.clientY };

            setImageStates(newImageStates);
        }

        const handleMouseUp = () => {
            setimageDragIndex(null);
        };

        const handeTouchEnd = () => {
            setimageDragIndex(null);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchmove', handeTouchMove);
        window.addEventListener('touchend', handeTouchEnd);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handeTouchMove);
            window.removeEventListener('touchend', handeTouchEnd);
        };

    }, [imageDragIndex, imageStates]);
        

    
    const handleTitleChange = (e) => {
        setInfoArray([{...infoArray[0], titleName: e.target.value}])
    };

    const handleTextChange = (e) => {
        setInfoArray([{...infoArray[0], description: e.target.value}])
    };

    // Header Image Change
    const handleHeaderChange = (e) => {
        console.log("header image Changes")
        const imageHeader = e.target.files[0];
        setHeaderData(imageHeader);

        const reader = new FileReader;

        if (imageHeader) {
            reader.readAsDataURL(imageHeader);
        }

        reader.onload = () => {
            setDisplayHeader(reader.result);
        }

    }

    const hangleCloseImages = (e) => {
        if (imagefileOpen === false) {
            setCurrentImageIndex(0);
            setImageFile([]);
            setHeaderData(null);
            setImageStates([]);
            setimageDragIndex(null);
        }
        setImageFileOpen((prev) => !prev);
    }

    const handleHeaderRemove = (e) => {
        setHeaderData(null);
        setDisplayHeader(null);
    }

    // Loads Images Data
    const loadImagesDisplay = (dataInfo) => {
        if (dataInfo && dataInfo.additionalImages) {
          const { newImageFiles, newImageStates } = dataInfo.additionalImages.reduce((acc, imgData, index) => {
            const imageID = `imageID_${index+1}`;
            const { image, scale, positionX, positionY } = imgData[imageID]

            acc.newImageFiles.push(image);
            acc.newImageStates.push({
                scale,
                position: { x: positionX, y: positionY },
                prevPosition: { x: positionX, y: positionY }
            });

            return acc;
        }, { newImageFiles: [], newImageStates: [] });

          setImageFile(newImageFiles);
          setImageStates(newImageStates);
        }
    };

    const handleImageChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const newImageState = selectedFiles.map(() => ({
          scale: 1,
          position: { x: 0, y: 0},
          prevPosition: { x: 0, y: 0}
        })
      );
    
        setImageFile([...imageFile, ...selectedFiles]);
        setImageStates([...imageStates, ...newImageState]);
        setCurrentImageIndex(imageFile.length);
    };

    const renderImage = (image) => {
        if (typeof image === 'string') {
            // Handle the case where the image is a URL string
            return image;
        } else if (image instanceof File || image instanceof Blob) {
            // Handle the case where the image is a File or Blob
            return URL.createObjectURL(image);
        } else {
            console.error("Invalid image type", image);
            return null;
        }
    };

    const handleDeleteCurrentImage = () => {
        if (imageFile[currentImageIndex].image instanceof File || imageFile[currentImageIndex].image instanceof Blob) {
            URL.revokeObjectURL(imageFile[currentImageIndex].image);
        }

        const updatedImages = [...imageFile];
        updatedImages.splice(currentImageIndex, 1);
        setImageFile(updatedImages);
    
        const updatedStates = [...imageStates];
        updatedStates.splice(currentImageIndex, 1);
        setImageStates(updatedStates);
        
        if (updatedImages.length > 0) {
            setCurrentImageIndex(0);
        }
      }

    const handleMouseDown = (e) => {
        const newImageStates = [...imageStates];
        //console.log(newImageStates);
        newImageStates[currentImageIndex].prevPosition = { x: e.clientX, y: e.clientY };
        setImageStates(newImageStates);
        setimageDragIndex(currentImageIndex);
    };

    const handleAddingExtraImages = (event) => {
        if (imageFile.length < 10) {
          const selectedFiles = Array.from(event.target.files);
          const newImageStates = selectedFiles.map(() => ({
            scale: 1,
            position: { x: 0, y: 0},
            prevPosition: { x: 0, y: 0}
          }));
    
          setImageFile([...imageFile, ...selectedFiles]);
          setImageStates([...imageStates, ...newImageStates]);
          setCurrentImageIndex(imageFile.length);
        } else {
          // You can display a message or handle the limit in any way you prefer
          console.log("Image limit reached. You can't add more images.");
        }
    };

    const nextPostIndex = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageFile.length);
    }

    const prevPostIndex = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1) % imageFile.length);
    }

    const submitInformation = async () => {
        const bob = "test";

        try {
            const response = await axios.post('/api/editInfo', {name: bob})
            //const response = await axios.post('/api/editInfo', bob);
            console.log(response);
        } catch (error) {
            console.log(error)
        }
    } 


    return(
        <>
        <div className="Edit-Page-Posts-Outer">
            <p>Edit Page</p>
            <div className="Post-Selection">
                <div className="Current-Post-ID-Edit">
                    <p>Current Post ID: {postId}</p>
                </div>
            </div>
            <div className="Edit-Page-File-Outside">
                {!imagefileOpen &&
                    <div className="blog-open-image-icon"> 
                        <div onClick={hangleCloseImages}>
                            <i className="blog-writeIcon fas fa-plus"/>
                        </div>    
                    </div>
                }
                {imagefileOpen && 
                    <div className="Edit-Page-File-Input-Background">
                        <div className="Edit-Page-Header-Image">
                            <div className="Edit-Page-Remove-Symbol">
                                <label onClick={handleHeaderRemove}>
                                    <i className="Edit-Page-Symbol-R fa-solid fa-trash"></i>
                                </label>
                            </div>
                            <div className="Edit-Page-Close-Symbol">
                                <label onClick={hangleCloseImages}>
                                    <i className="Edit-Page-Symbol-C fas fa-times-circle"></i>
                                </label>
                            </div>
                            {!headerData && 
                                <label htmlFor="Edit-Page-Header-Input" className="Edit-Page-HImage-Closed">
                                    <div className="fileInput-files-symbol">
                                        <label htmlFor="Edit-Page-Header-Input">
                                            <i className="blog-OpenFileIcon fa-solid fa-photo-film"/>
                                        </label>
                                    </div>
                                    <div className="fileInputBackground-top-text">
                                        <span className="fileInputBackground-top-text">Add Header Image</span>
                                    </div>
                                    <div className="fileInputBackground-bot-text">
                                        <span>or drag and drop</span>
                                    </div>
                                    <input id="Edit-Page-Header-Input" type="file" style={{ display: "none" }} onChange={handleHeaderChange}/>
                                </label>
                            }
                            {headerData &&
                                <div className="Edit-Page-HImage-Open">
                                    <div className="blog-writeDisplay-Image">
                                        <img src={displayHeader} alt="Uploaded"/> 
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="Edit-Page-Display-Images">
                            {imageFile.length === 0 && (
                                <label htmlFor="blog-fileInput" className="fileInputBackground-image-closed">
                                    <div className="fileInput-files-symbol">
                                        <label htmlFor="blog-fileInput">
                                            <i className="blog-OpenFileIcon fa-solid fa-photo-film"/>
                                        </label>
                                    </div>
                                    <div className="fileInputBackground-top-text">
                                        <span className="fileInputBackground-top-text">Add Photos/Videos</span>
                                    </div>
                                    <div className="fileInputBackground-bot-text">
                                        <span>or drag and drop</span>
                                    </div>
                                    <input 
                                    id="blog-fileInput" 
                                    type="file" 
                                    style={{ display: "none" }} 
                                    onChange={handleImageChange} 
                                    multiple
                                    />
                                </label>
                            )}
                            {imageFile.length > 0 && (
                                <div className="fileInputBackground-image-open">
                                    <div className="blog-writeHeaderImage">
                                        <div className="blog-writeHeaderImage-Border">
                                            <img
                                            className="blog-image-styling" 
                                            alt=""
                                            style={{
                                                transform: `scale(${imageStates[currentImageIndex]?.scale || 1})
                                                translate(${imageStates[currentImageIndex]?.position.x || 0}px, 
                                                  ${imageStates[currentImageIndex]?.position.y || 0}px)
                                                `
                                              }}
                                            draggable={false}
                                            onMouseDown={handleMouseDown}
                                            src={renderImage(imageFile[currentImageIndex])}
                                            >
                                            </img>
                                        </div>
                                    </div>
                                    <div className="fileInput-AddMore-Symbol">
                                        <label>
                                            <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={handleAddingExtraImages}
                                            multiple
                                            />
                                            <i className="blog-addMoreImagesIcon fas fa-plus"></i>
                                        </label>  
                                    </div>
                                    <div className="fileInput-Remove-Symbol">
                                        <label onClick={handleDeleteCurrentImage}>
                                            <i className="blog-addRemoveImagesIcon fa-solid fa-trash"></i>
                                        </label>  
                                    </div>
                                    <div className="blog-image-scale-location">
                                        <input
                                            type="range"
                                            min="0.1"
                                            max="3"
                                            step="0.1"
                                            value={imageStates[currentImageIndex]?.scale || 1}
                                            onChange={handleSliderChange}
                                        />
                                    </div>
                                    {imageFile.length > 1 &&
                                        <div>
                                            <button onClick={prevPostIndex}>Previous</button>
                                            <button onClick={nextPostIndex}>Next</button>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
            <div className="Edit-Page-Main-Text">
                <input
                className="Edit-Page-Title Edit-Page-Defaults"
                type="text"
                placeholder="Title"
                autoFocus={true}
                value={infoArray[0].titleName}
                onChange={handleTitleChange}>
                </input>
                <textarea
                className="Edit-Page-Description Edit-Page-Defaults"
                type="text"
                placeholder="Input information..."
                autoFocus={true}
                value={infoArray[0].description}
                onChange={handleTextChange}
                />
            </div>
            <div className="Edit-Page-Ingredients-Text">
                <p>Input Ingredients A</p>
                <EditIngredients typeOfField="ingredients" data={ingredientsA} setData={setIngredientsA} fieldNames={{ name: 'Name', percentage: 'Percentage', amount: 'Amount' }}/>
                <p>Input Ingredients B</p>
                <EditIngredients typeOfField="ingredients" data={ingredientsB} setData={setIngredientsB} fieldNames={{ name: 'Name', percentage: 'Percentage', amount: 'Amount' }}/>
                <p>Input Ingredients C</p>
                <EditIngredients typeOfField="ingredients" data={ingredientsC} setData={setIngredientsC} fieldNames={{ name: 'Name', percentage: 'Percentage', amount: 'Amount' }}/>
                <p>Technologies</p>
                <EditIngredients typeOfField="technologies" data={technologies} setData={setTechnologies} fieldNames={{ number: 'Number', description: 'Description' }}/>
            </div>
            <div>
                <button onClick={submitInformation}>
                    Submit
                </button>
            </div>

        </div>
        <BlogFooter />
        </>
    )
}