import RoutesBlog from './Routing/RoutesBlog';
import TopBar from "../src/components/navigation-components-blog/TopBar";
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';



function App() {
  return (
    <AuthProvider>
        <TopBar/>
       <RoutesBlog />
    </AuthProvider>
  );
}

export default App;
