import "./dynamicposts.scss";
import BlogFooter from "../../components/blog-footer/BlogFooter";
import DynamicPost from "../../components/blog-posts-functionality/blog-dynamic-post/dynamicPost";
import Breadcrumbs from "../../components/navigation-components-blog/BreadcrumbNav"
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from "axios";

export default function DynamicPosts() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const postId = queryParams.get('postId');
    const option = queryParams.get('option');
    const [post, setPost] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    
    // Future move the login from dynamicpost and pass data to it from here

    useEffect(() => {
        //console.log(option);
        const fetchPost = async () => {
          try {
            const response = await axios.get(`/api/posts/${postId}`,{
                params: {
                  option: option
                }
            });
            setPost(response.data);
            setProfileImage(response.data.userPostingImage);
            //console.log(response.data);
            //console.log(`Sent dynamic page load for the post ${postId}`);
          } catch (error) {
            console.error('Error fetching post:', error);
          }
        };
      
        console.log('Fetching post...');
        fetchPost();
        //getUserImage()
    }, [postId]);
    console.log(post.title);
    //console.log(`${post.title}`);
    return(
        <>
        <div className="dynamic-page-container">
                <div className="DynaminPosts-Container">
                    {post && <Breadcrumbs specificOption={option} titlePost={post.title} />}
                    <div className="DynamicPost-Inner-Container">
                        <DynamicPost />
                    </div>
                </div>
            <BlogFooter/>
        </div>
        </>
    )
}