import './dynamicpostingredients.scss'
import React from 'react';

export default function DynamicPostIngredients({ header, ingredients }) {
    //console.log(ingredients[0].name);
    return (
        <div className='drop-down-menu-ingredient-container'>
                <div className='header-phase'>
                <span>{header}</span>
                </div>
                <ul className='dropDownMenuDetailsIngredients'>
                    {ingredients.map((ingredient, index) => (
                        <React.Fragment key={index}>
                            {!(ingredient.name.trim() === "" && ingredient.percentage.trim() === "" && ingredient.amount.trim() === "") && (
                                <li key={index}>
                                    <div className='number-menu-details-ingredients'>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className='details-menu-details-ingredients'>
                                        <h2>{ingredient.name}</h2>
                                        <p className='details-menu-details-percentage-w70'>{ingredient.percentage}</p>
                                        <p className='details-menu-details-percentage-w70'>{ingredient.amount}</p>
                                    </div>
                                </li>
                            )}
                            {index !== ingredients.length - 1 &&
                                <li className='divider-details-menu'></li>
                            }
                        </React.Fragment>
                    ))}
                </ul>
        </div>
    )
};