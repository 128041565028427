import "./login.scss"
import BlogFooter from "../../components/blog-footer/BlogFooter";
import { useNavigate } from "react-router-dom"; // For redirecting after successful login
import { useAuth } from "../../context/AuthContext"; // Adjust the path as necessary
import axios from "axios";
import { useState, useEffect } from "react";
import React from 'react';

//Test first making a login page then move it into a component so the person can login from anywher
export default function LoginPage() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/signin', {userName, password} );
            const data = response.data;
            
            setUserName('');
            setPassword('');

            if (data.token) {
                if (data.refreshToken) {
                    localStorage.setItem('refreshToken', data.refreshToken);
                }
                
                login();
                navigate("/");
            } else {
                setLoginMessage("Improper Login credentials provided.");
            }

        } catch (error){
            console.error("Login Error:", error.response.data.error);
            setLoginMessage(error.response.data.message || "Invalid Login Credentials");
            setPassword('');
        }

    }


    const handleUserName = (event) => {
        setUserName(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }


    return (
        <div className="loginBlog">
            <div className="login-container">
                <div className="login-box">
                    <h2>Login Form</h2>
                    <form className= "loginForm" onSubmit={handleLogin}>
                        <div className="loginFrom-box">
                            <label className={userName && 'filled-login'}>Username</label>
                            <input type="text" name="username" value={userName}  onChange={handleUserName} required />
                        </div>
                        <div className="loginFrom-box">
                            <label className={password && 'filled-login'}>Password</label>
                            <input type="password" name="password" value={password} autoComplete="off" onChange={handlePassword} required />
                            </div>
                        <a type="submit" onClick={handleLogin}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            login
                        </a>
                    </form>
                    <p>{loginMessage}</p>
                </div>
            </div>
            <BlogFooter/>
        </div>
    )
}